import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import theme from 'site-react/theme';

const backgroundPillStyle = css`
  background: var(--color-neutral-900);
  border-radius: var(--space-lg);
  padding: var(--space-sm);
`;

const currentPositionStyle = css`
  color: var(--color-white);
  font-weight: var(--font-weight-medium);
`;

const imageQuantityStyle = css`
  color: var(--color-neutral-500);
`;

const positionStyle = css`
  bottom: 0;
  font-weight: var(--font-weight-medium);
  padding: 0 calc(var(--space-base) * 12) var(--space-md) var(--space-sm);
  position: absolute;
  right: 0;

  @media (min-width: ${theme.breakpoints.md}px) {
    text-align: right;
    /* Everything's back in the grid now, so undo our inset */
    margin-left: 0;
    margin-right: 0;
  }
`;

const ImageCarouselPosition = ({ current, max }) => {
  return (
    <>
      <span
        aria-atomic
        aria-live="polite"
        className={theme.utilities.visuallyHidden}
      >
        Image {current} of {max}
      </span>
      <span className={positionStyle}>
        <span className={backgroundPillStyle}>
          <span aria-hidden className={currentPositionStyle}>
            {String(current).padStart(2, '0')}
          </span>
          <span className={imageQuantityStyle}>
            {` / ${String(max).padStart(2, '0')}`}
          </span>
        </span>
      </span>
    </>
  );
};

ImageCarouselPosition.propTypes = {
  /**
   * The currently-visible image. 1-indexed.
   */
  current: PropTypes.number.isRequired,

  /**
   * The total number of images.
   */
  max: PropTypes.number.isRequired,
};

export default ImageCarouselPosition;
