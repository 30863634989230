import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'site-react/components/form';
import { LinkAsButton } from 'site-react/components/navigation';
import ModalNew from 'site-react/components/page/ModalNew';
import { TextWithIcon } from 'site-react/components/typography';
import { OfficeAccessOptions } from 'site-react/data/listing/ViewingRequestContext';

import styles from './OfficeCardButtonGroup.module.css';
import FullTimeQuoteModal from '../../../../../FullTimeQuoteModal';
import PartTimeQuoteModal from '../../../../../PartTimeQuoteModal';

const OfficeCardButtonGroup = ({
  buildingName,
  location,
  officeAccess,
  pricePlanId,
  pricePlanSize,
  price,
  userEmail,
  uspContent = null,
}) => {
  const pathname = usePathname();

  const buttonProps = {
    analyticsMetadata: {
      label: 'Book a viewing (Office Card)',
      location: 'office-card',
      pricePlanId,
    },
    'aria-label': 'Book a viewing',
    isBlock: true,
    isCompact: true,
    name: 'Book a viewing',
    styleAtSmall: 'auto',
    styleType: 'primary',
  };

  const QuoteModal =
    officeAccess === OfficeAccessOptions.PartTime
      ? PartTimeQuoteModal
      : FullTimeQuoteModal;

  const quoteModalProps =
    officeAccess === OfficeAccessOptions.PartTime
      ? {
          buildingName: buildingName,
          location: location,
          pricePlanId: pricePlanId,
          pricePlanSize: pricePlanSize,
          userEmail: userEmail,
        }
      : {
          buildingName: buildingName,
          location: location,
          pricePlanId: pricePlanId,
          pricePlanPrice: price,
          pricePlanSize: pricePlanSize,
          userEmail: userEmail,
        };

  return (
    <div className={styles['button-group']}>
      <div className={styles['button-wrapper']}>
        <LinkAsButton
          {...buttonProps}
          href={{
            pathname: `${pathname}/book-viewing`,
            query: {
              access: officeAccess,
              option: pricePlanId,
            },
          }}
        >
          <TextWithIcon
            contentType="content2"
            iconName="bolt"
            iconPosition="left"
            iconSize="md"
            labelType="label1"
            text={buttonProps.name}
          />
        </LinkAsButton>
      </div>

      <div className={styles['link-wrapper']}>
        <ModalNew
          id="get-a-quote"
          label="Get a quote"
          renderTrigger={({ openModal }) => (
            <Button
              data-testid="get-a-quote-button"
              isBlock
              isCompact
              name="Get a quote"
              onClick={openModal}
              styleType="secondary"
              type="button"
            >
              Get a quote
            </Button>
          )}
        >
          <QuoteModal {...quoteModalProps} />
        </ModalNew>
      </div>
      {uspContent && <div className={styles['usp-wrapper']}>{uspContent}</div>}
    </div>
  );
};

OfficeCardButtonGroup.propTypes = {
  location: PropTypes.string.isRequired,
  officeAccess: PropTypes.oneOf(Object.values(OfficeAccessOptions)).isRequired,
  pricePlanId: PropTypes.number.isRequired,
  pricePlanSize: PropTypes.number.isRequired,
  uspContent: PropTypes.node,
};

export default OfficeCardButtonGroup;
