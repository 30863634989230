import { css } from '@emotion/css';
import Link from 'next/link';
import React from 'react';

import { Grid, Section } from 'site-react/components/page';
import { Heading, TextWithIcon } from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';
import theme from 'site-react/theme';

import GuideItem from './GuideItem';

const linkListStyle = css`
  display: flex;
  flex-direction: column;
  gap: var(--space-md);

  @media (min-width: ${theme.breakpoints.sm}px) {
    column-count: 2;
    display: block;
    gap: 0;

    & > * {
      /*
       * Margin feels more intuitive here. But with multi-column layout,
       * if we use margin, the space will sometimes flow over into the next
       * column, causing misalignment. By using padding instead, plus
       * break-inside: avoid, the padding will stay within the child element.
       */
      break-inside: avoid;
      padding-bottom: var(--space-md);
    }
  }
`;

const rulerStyle = css`
  border-bottom: 1px solid var(--color-neutral-100);
  padding-bottom: var(--space-sm);
`;

const ResourcesMenuContent = () => {
  return (
    <Section verticalPadding="lg">
      <Grid>
        <Grid.Item colSpan="6" rowSpan="2">
          <div className={rulerStyle}>
            <Heading level="3" type="title4">
              Help Guides
            </Heading>
          </div>

          <VerticalSpacing size="md" />
          <UnstyledList>
            <GuideItem
              href="/blog/everything-about-renting-private-day-offices"
              imageAlt="Renting private day offices"
              imagePath="/site/navigation/help-guides/everything-about-flexible-office-space.jpeg"
              label="Everything You Need to Know About Renting Private Day Offices"
            />
            <GuideItem
              href="/blog/hybrid-working-ultimate-guide"
              imageAlt="Hybrid working 101"
              imagePath="/site/navigation/help-guides/hybrid101.png"
              label="Hybrid Working 101: The Ultimate Guide to Blended Working"
            />
            <GuideItem
              href="/blog/everything-about-flexible-office-space"
              imageAlt="Flexible office space"
              imagePath="/site/navigation/help-guides/renting-private-offices.png"
              label="Everything You Need to Know About Flexible Office Space"
            />

            <VerticalSpacing size="lg" />
            <Link href="/blog/moving-office">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text="More help guides"
              />
            </Link>
          </UnstyledList>
        </Grid.Item>
        <Grid.Item colSpan="6">
          <div className={rulerStyle}>
            <Heading level="3" type="title4">
              Learn More
            </Heading>
          </div>
          <VerticalSpacing size="md" />
          <UnstyledList className={linkListStyle}>
            <li>
              <Link href="/guides/hot-desk">Hot desking</Link>
            </li>
            <li>
              <Link href="/guides/coworking">Coworking</Link>
            </li>
            <li>
              <Link href="/blog/office-space-calculator">
                Calculate my Budget
              </Link>
            </li>
            <li>
              <Link href="/guides/leased-offices">Leasing</Link>
            </li>
            <li>
              <Link href="/guides/serviced-offices">Serviced Offices</Link>
            </li>
          </UnstyledList>
        </Grid.Item>
        <Grid.Item colSpan="6">
          <div className={rulerStyle}>
            <Heading level="3" type="title4">
              Hubble
            </Heading>
          </div>
          <VerticalSpacing size="md" />
          <UnstyledList className={linkListStyle}>
            <li>
              <Link href="/about">About Us</Link>
            </li>
            <li>
              <Link href="/contact">Contact</Link>
            </li>
            <li>
              <Link href="/blog">Blog</Link>
            </li>
          </UnstyledList>
        </Grid.Item>
      </Grid>
    </Section>
  );
};

export default ResourcesMenuContent;
