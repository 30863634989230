import { css, cx } from '@emotion/css';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';

import { MaterialIcon, Price } from 'site-react/components/typography';
import {
  ViewingRequestContext,
  OfficeAccessOptions,
} from 'site-react/data/listing/ViewingRequestContext';
import theme from 'site-react/theme';

const availableFromDateStyle = css`
  ${theme.smallLabel};
  color: var(--color-neutral-500);
  display: none;
  grid-area: availability;
  text-transform: uppercase;
  @media (min-width: ${theme.breakpoints.lg}px) {
    display: block;
  }
`;

const availableNowStyle = css`
  ${availableFromDateStyle};
  color: var(--color-neutral-900);
`;

const capacityStyle = css`
  font: var(--font-style-content-3);
  display: none;
  grid-area: capacity;
  margin-right: var(--space-md);
  @media (min-width: ${theme.breakpoints.lg}px) {
    display: block;
  }
`;

const displayComponentStyle = css`
  align-items: center;
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--space-xs);
  display: grid;
  grid-template-areas: 'detail carat';
  grid-template-columns: 1fr auto;
  justify-items: stretch;
  padding: var(--space-sm);
  width: 100%;

  @media (min-width: ${theme.breakpoints.lg}px) {
    grid-template-areas:
      'detail detail carat'
      'capacity availability carat';
    grid-template-columns: auto 1fr auto;
  }
`;

const displayContentStyle = css`
  font: var(--font-style-content-3);
  display: flex;
  grid-area: detail;
  flex-direction: column;

  span:first-child {
    font-weight: var(--font-weight-medium);
  }

  span:nth-child(2) {
    display: none;
  }

  span:last-child {
    color: var(--color-neutral-500);
  }

  @media (min-width: ${theme.breakpoints.lg}px) {
    font-weight: var(--font-weight-medium);

    flex-direction: row;
    > span:nth-child(2) {
      display: block;
    }

    span:last-child {
      color: var(--color-neutral-900);
    }
  }
`;

const dropdownTitleStyle = css`
  font: var(--font-style-content-2);
  color: var(--color-neutral-900);
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  margin: 0;
  padding: 0;

  @media (min-width: ${theme.breakpoints.lg}px) {
    display: inline;
    font-size: var(--font-size-sm);
  }
`;

const DisplayComponent = ({ selectedPricePlan }) => {
  const { officeAccess } = useContext(ViewingRequestContext);

  const dropDownPriceDisplay = () => {
    if (officeAccess === OfficeAccessOptions.PartTime) {
      return <span>Part-time Private Office</span>;
    } else {
      return (
        <>
          <span>Private Office</span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <Price amount={selectedPricePlan.price} /> / mo (excl VAT)
          </span>
        </>
      );
    }
  };

  const getTitleCopy = () => {
    let titleCopy = `~${selectedPricePlan.capacity} people`;
    if (selectedPricePlan?.sqft > 0) {
      titleCopy += `, ${selectedPricePlan.sqft} sqft`;
    }

    return titleCopy;
  };

  let availableFromText = 'Available now';
  let parsedAvailableFromDate = '';

  if (selectedPricePlan?.availableFrom) {
    parsedAvailableFromDate = DateTime.fromFormat(
      selectedPricePlan.availableFrom,
      'yyyy-MM-dd',
    );
  }

  const isAvailableNow = !(
    selectedPricePlan?.availableFrom &&
    parsedAvailableFromDate > DateTime.local()
  );
  if (!isAvailableNow) {
    availableFromText = `Available ${parsedAvailableFromDate.toFormat(
      'dd MMM yy',
    )}`;
  }

  return (
    <div className={displayComponentStyle}>
      {selectedPricePlan ? (
        <span className={displayContentStyle}>
          {dropDownPriceDisplay(selectedPricePlan)}
        </span>
      ) : (
        <span className={dropdownTitleStyle}>-</span>
      )}
      {selectedPricePlan ? (
        <>
          <span className={capacityStyle}>{getTitleCopy()}</span>
          <span
            className={cx(availableFromDateStyle, {
              [availableNowStyle]: isAvailableNow,
            })}
          >
            {availableFromText}
          </span>
        </>
      ) : null}
      <MaterialIcon
        className={css`
          grid-area: carat;
        `}
        iconType="arrow_drop_down"
      />
    </div>
  );
};

export default DisplayComponent;
