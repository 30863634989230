import cn from 'classnames';

import { linkedin } from 'site-react/assets';
import { Button } from 'site-react/components/form';
import { OutboundLink } from 'site-react/components/navigation';
import { HubbleLogo } from 'site-react/components/page';
import ModalNew from 'site-react/components/page/ModalNew';
import {
  Heading,
  MaterialIcon,
  Paragraph,
} from 'site-react/components/typography';
import { Asset, ImgixImage } from 'site-react/components/utility';

import styles from '../../Callout.module.css';
import AdvisorModalContent from '../AdvisorModalContent';

const CalloutAdvisorContent = ({
  advisor,
  heading,
  isRecommendationListExpanded = false,
}) => {
  const advisorImageProps = {
    alt: 'Advisor',
    height: 56,
    src: advisor.image,
    width: 56,
  };

  return (
    <>
      <div
        className={cn(styles['Callout'], {
          [styles['isRecommendationListExpanded']]:
            isRecommendationListExpanded,
        })}
      >
        <div
          className={styles['Callout-heading']}
          data-testid="user-recommendations-dashboard-callout"
        >
          <HubbleLogo isLink={false} isLogoTextDisplayed size={48} />
          <Heading level="1" type="title3">
            {heading}
          </Heading>
        </div>

        <div
          className={cn(styles['u-hideBelowMd'], {
            [styles['isRecommendationListExpanded']]:
              isRecommendationListExpanded,
          })}
        >
          <div className={styles['Callout-advisorDetails']}>
            <ImgixImage {...advisorImageProps} />

            <div>
              <Paragraph isMarginless>Hand picked by your advisor</Paragraph>
              <OutboundLink
                href={advisor.linkedin}
                rel="noopener noreferrer"
                styleType="secondary"
                target="_blank"
              >
                <div className={styles['Callout-linkedin']}>
                  <b>{advisor.name}</b>
                  <Asset
                    alt="LinkedIn"
                    height="16px"
                    sizingBehaviour="fixed"
                    src={linkedin}
                    width="16px"
                  />
                </div>
              </OutboundLink>
            </div>

            <ModalNew
              label="Contact advisor"
              renderTrigger={({ openModal }) => (
                <div>
                  <Button
                    isCompact
                    name="Contact advisor"
                    onClick={openModal}
                    styleType="secondary"
                    type="button"
                  >
                    Contact {advisor.name.split(' ')[0]}
                  </Button>
                </div>
              )}
            >
              <AdvisorModalContent advisor={advisor} />
            </ModalNew>
          </div>
        </div>
      </div>

      <div
        className={cn(styles['Callout-advisorImage'], styles['u-hideAboveMd'])}
      >
        <ModalNew
          label="Contact advisor"
          renderTrigger={({ openModal }) => (
            <button
              className={styles['Callout-advisorImageContent']}
              onClick={openModal}
              type="button"
            >
              <ImgixImage {...advisorImageProps} />
              <MaterialIcon
                className={styles['Callout-icon']}
                iconType="chat_bubble"
              />
            </button>
          )}
        >
          <AdvisorModalContent advisor={advisor} />
        </ModalNew>
      </div>
    </>
  );
};

export default CalloutAdvisorContent;
