import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { useSearchParams } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Paragraph } from 'site-react/components/typography';
import {
  HorizontalRule,
  Spinner,
  VerticalSpacing,
} from 'site-react/components/utility';
import BookingSummaryCard from 'site-react/features/BookingSummaryCard';
import { ProductPriceFormatter } from 'site-react/helpers/productPriceFormatter';
import usePriceType from 'site-react/hooks/usePriceType';
import { BuildingPropTypes } from 'site-react/proptypes';
import theme from 'site-react/theme';

const contentWrapperStyle = css`
  border: 1px solid var(--color-neutral-50);
  border-radius: var(--space-md);
  box-shadow: var(--shadow-resting);
  margin-top: var(--space-xl);
  padding: var(--space-md);

  @media (min-width: ${theme.breakpoints.md}px) {
    padding: var(--space-lg);
  }
`;

const priceFormatter = new ProductPriceFormatter('en-GB', 'GBP');

const BookingOverview = ({
  bookingDuration = null,
  product,
  selectedBuilding,
}) => {
  // product can be 'coworking', 'private-office' or
  // any meeting room following this format: 16person-meeting-room-23ph
  // We need to extract the product type and the price using the URL
  const [productDetails, setProductDetails] = useState({
    meetingRoomCapacity: null,
    price: null,
  });

  const searchParams = useSearchParams();

  const next = searchParams.get('next');

  const nextParams = new URLSearchParams(
    decodeURIComponent(next).split('?')[1],
  );
  const date = nextParams.get('date');
  /* eslint-disable sort-keys */
  const formattedDate = DateTime.fromISO(date).toLocaleString({
    weekday: 'short',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });
  /* eslint-enable sort-keys */
  const priceType = usePriceType();

  useEffect(() => {
    const getProductDetails = (product, buildingProducts) => {
      // First find the product that is currently selected via the URL
      // from the building's available products
      // then extract and format the data we need, namely the price
      // and the room capacity if meeting room
      let currentProduct =
        buildingProducts &&
        buildingProducts.find((prod) => {
          if (prod.productType === 'meeting-room') {
            return product.includes(`${prod.capacity}person`);
          } else {
            return product.includes(prod.productType);
          }
        });
      let roomCapacity = null;
      let price = '';

      const formattedCoworkingAndPrivateOfficePrice =
        priceFormatter.getFormattedPrice(
          currentProduct[priceFormatter.priceTypeConfig[priceType]],
          priceType,
        );

      const durationProperty =
        bookingDuration === 'fullDay' ? 'perDay' : 'perHour';

      const formattedMeetingRoomPrice = priceFormatter.getFormattedPrice(
        currentProduct[
          priceFormatter.meetingRoomPriceConfig[durationProperty][priceType]
        ],
        priceType,
      );

      if (product === 'coworking' || product === 'private-office') {
        price = `${formattedCoworkingAndPrivateOfficePrice} / person / day`;
        roomCapacity = null;
      } else if (product.includes('meeting-room')) {
        price = `${formattedMeetingRoomPrice} / ${
          bookingDuration === 'fullDay' ? 'day' : 'hour'
        }`;

        roomCapacity = currentProduct.capacity;
      } else {
        price = 'No price available';
        roomCapacity = null;
      }
      return {
        meetingRoomCapacity: roomCapacity,
        price,
      };
    };

    selectedBuilding &&
      setProductDetails(getProductDetails(product, selectedBuilding.products));
  }, [selectedBuilding, product, priceType, bookingDuration]);

  if (selectedBuilding) {
    return (
      <>
        <aside aria-label="booking-summary" className={contentWrapperStyle}>
          <BookingSummaryCard
            bookingType={product}
            building={selectedBuilding}
            buildingImagePath={selectedBuilding.images[0].img}
            formattedBookingDate={formattedDate}
            headCount={productDetails.meetingRoomCapacity}
            optionParam={product}
          />

          <VerticalSpacing size="md" />

          <HorizontalRule />

          <VerticalSpacing size="md" />
          <Paragraph isMarginless type="content2">
            <strong>Rate</strong>
          </Paragraph>
          <Paragraph isMarginless type="content3">
            {productDetails.price}
          </Paragraph>
        </aside>

        <VerticalSpacing size="lg" />

        <Paragraph isCentered isMarginless type="content3">
          <strong>You can review your booking and pay on the next page</strong>
        </Paragraph>
      </>
    );
  } else {
    return <Spinner size="small" />;
  }
};

BookingOverview.propTypes = {
  bookingDuration: PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    'fullDay',
  ]),
  product: PropTypes.string.isRequired,
  selectedBuilding: BuildingPropTypes.isRequired,
};

export default BookingOverview;
