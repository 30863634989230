import PropTypes from 'prop-types';
import { useContext } from 'react';

import { TextWithIcon } from 'site-react/components/typography';
import config from 'site-react/config';
import { AlertContext } from 'site-react/data/core/AlertContext';

import styles from './ShareButton.module.css';

function copyRecommendationListLink(token, onSuccess) {
  const url = `${config.HUBBLE_URL}/recommendations/${token}`;
  navigator.clipboard.writeText(url);

  onSuccess('Link copied to clipboard');
}

export default function ShareButton({ token }) {
  const { addSuccess } = useContext(AlertContext);

  return (
    <button
      aria-label="Copy link to share"
      className={styles.ShareButton}
      onClick={() => copyRecommendationListLink(token, addSuccess)}
    >
      <span className={styles['u-displayBelowMd']}>
        <b>
          <TextWithIcon iconName="ios_share" iconPosition="left" text="Share" />
        </b>
      </span>
      <span className={styles['u-displayAboveMd']}>
        <TextWithIcon
          iconName="content_copy"
          iconPosition="left"
          text="Copy link to share"
        />
      </span>
    </button>
  );
}

ShareButton.propTypes = {
  token: PropTypes.string.isRequired,
};
