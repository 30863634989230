import { css } from '@emotion/css';
import PropTypes from 'prop-types';
import React from 'react';

import { Link } from 'site-react/components/navigation';
import { Heading, TextWithIcon } from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';
import theme from 'site-react/theme';

const listStyle = css`
  column-count: 2;
  column-fill: balance;
  column-gap: var(--space-md);

  @media (min-width: ${theme.breakpoints.md}px) {
    column-count: initial;

    & > * {
      margin-bottom: var(--space-xs);
    }
  }
`;

const linkStyle = css`
  font-size: var(--font-size-xs);
`;

const AreaItem = ({ label, children, href = null }) => (
  <div aria-label={label}>
    <Heading level="3" type="content2">
      <b>{label}</b>
    </Heading>
    <VerticalSpacing size="sm" />
    <UnstyledList className={listStyle}>{children}</UnstyledList>
    {href && (
      <>
        <VerticalSpacing size="md" />
        <div className={linkStyle}>
          <Link
            analyticsMetadata={{
              label,
              location: 'navigation',
            }}
            href={href}
            isAppLink
          >
            <TextWithIcon
              contentType="content3"
              iconName="chevron_right"
              text={<b>View all {label}</b>}
            />
          </Link>
        </div>
      </>
    )}
  </div>
);

AreaItem.propTypes = {
  /**
   * Children, expecting li elements
   */
  children: PropTypes.node.isRequired,
  /**
   * href of all areas link
   */
  href: PropTypes.string,
  /**
   * Label, to present at the head of this component
   */
  label: PropTypes.string.isRequired,
};

export default AreaItem;
