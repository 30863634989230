import { css } from '@emotion/css';

import { UICard } from 'site-react/components/page';
import {
  Heading,
  MaterialIcon,
  TextWithIcon,
} from 'site-react/components/typography';
import theme from 'site-react/theme';

const requestToBookStyle = css`
  margin-top: var(--space-md);
  text-align: left;
`;

const cardHeadingStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--space-md);
  padding: var(--space-md);

  @media (min-width: ${theme.breakpoints.sm}px) {
    flex-direction: row;
    align-items: center;
    padding: 18px var(--space-lg);
  }
`;

const responseTimeWrapperStyle = css`
  display: flex;
  align-items: center;
  font-size: var(--font-size-xs);
`;

const iconStyle = css`
  font-size: var(--font-size-lg);
  margin-right: var(--space-sm);
`;

const ProductBookingDetailsWrapper = ({
  heading,
  bookingResponseTime,
  showInstantBook = false,
  children,
}) => {
  const headingContent = (
    <div className={cardHeadingStyle}>
      {heading && (
        <Heading level="3" type="title4">
          {heading}
        </Heading>
      )}
      {!showInstantBook && bookingResponseTime && (
        <span className={responseTimeWrapperStyle}>
          <MaterialIcon className={iconStyle} iconType="alarm" />
          <span>
            <abbr title="Average">Av. </abbr>
            Response time{' '}
            <b>
              {bookingResponseTime} business hour
              {bookingResponseTime > 1 ? 's' : ''}
            </b>
          </span>
        </span>
      )}
      {showInstantBook && (
        <TextWithIcon
          contentType="content3"
          iconName="bolt"
          iconPosition="left"
          text="Instant Book"
        />
      )}
    </div>
  );

  return (
    <div className={requestToBookStyle}>
      <UICard style={{ padding: 0 }}>
        {headingContent}
        {children}
      </UICard>
    </div>
  );
};

export default ProductBookingDetailsWrapper;
