import PropTypes from 'prop-types';

import { OutboundLink } from 'site-react/components/navigation';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { ImgixImage, VerticalSpacing } from 'site-react/components/utility';

import styles from './UserNote.module.css';

export default function UserNote({ advisor, buildingName, note }) {
  return (
    <>
      <Heading type="content3">
        <b>{buildingName}</b>
      </Heading>

      <VerticalSpacing size="sm" />

      <Heading level="1" type="title4">
        A note from your Hubble advisor
      </Heading>

      <VerticalSpacing size="md" />

      {advisor && (
        <>
          <div className={styles['UserNote-advisor']}>
            <ImgixImage
              alt="Advisor"
              height={56}
              src={advisor.image}
              width={56}
            />
            <Heading type="title4">{advisor.name}</Heading>
          </div>
          <VerticalSpacing size="xs" />
        </>
      )}

      <Paragraph isMarginless>{note}</Paragraph>

      {advisor && (
        <>
          <VerticalSpacing size="md" />
          <OutboundLink href={`mailto:${advisor.email}`} styleType="secondary">
            <TextWithIcon
              iconColor="neutral-900"
              iconName="mail"
              iconPosition="left"
              text={`Message ${advisor.name.split(' ')[0]}`}
            />
          </OutboundLink>
        </>
      )}
    </>
  );
}

UserNote.propTypes = {
  advisor: PropTypes.shape({
    email: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
  }),
  buildingName: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
};
